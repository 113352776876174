// change the theme
/*
$theme-colors: (
  "primary": #0074d9,
  "danger": #ff4136
);
*/

$primary: #008c69;
$secondary: #004d8c;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.logo img{
    width: 200px;
    margin-left: 15px;
    border-bottom: none;
  }
  
  .navbar-toggler{
    margin-right: 15px;
  }
  
  .navbar-nav {
    margin-left: 15px;
  }

  a.image {
    
  }

  a.image img {
    width: 100%;
  }

  div.image img {
    width: 100%;
  }

  div.articel-teaser-section > div > h4 > header > h2 {
    
  }

  .btn-primary {
    color: $light;   
  }   

  section.no-padding-bottom,div.no-padding-bottom {
    padding-bottom: 0 !important;
  }

  .contactLink {
    display: flex;
    padding-top: 2px; 
    margin-bottom: 2px;
    align-items: center;
  }


  .faIcon {
    margin-top: 2px;
    margin-right: 15px;
  }

  
  a.contactLink {
    
  }

  .map {
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .map a{
    align-self: right;
  }

  .portrait {
    margin-bottom: 2rem;
  }

  .footer-footer {
    
  }
  
  .footer-footer a{
    margin-left:10px;
  }

  .portrait .card {
    word-wrap: normal;
  }

  .btn-secondary {
    background: #e0e7f8;
    color: $secondary;
  }

  .btn-primary {
    color: $primary;
    background: #e2f8f7;
  }

  .linkIcon {
    margin-left:10px;
    margin-right: 5px;
  }